<template>
  <div>
    <a-table :data-source="list" bordered :pagination="false">
      <a-table-column title="总行名称">
        <template slot-scope="text">
          <a-input v-if="text.editable" v-model="editingBank.headBank" />
          <span v-else>{{ text.headBank }}</span>
        </template>
      </a-table-column>
      <a-table-column title="支行名称">
        <template slot-scope="text">
          <a-input v-if="text.editable" v-model="editingBank.branchBank" />
          <span v-else>{{ text.branchBank }}</span>
        </template>
      </a-table-column>
      <a-table-column title="银行账户">
        <template slot-scope="text">
          <a-input v-if="text.editable" v-model="editingBank.account" />
          <span v-else>{{ text.account }}</span>
        </template>
      </a-table-column>
      <a-table-column title="操作" align="center">
        <template slot-scope="text, row, index">
          <a-space v-if="text.editable">
            <span style="color: #1890ff; cursor: pointer" @click="save(index)"
              >保存</span
            >
            <span
              style="color: #f00; cursor: pointer"
              @click="cancel(text, index)"
              >取消</span
            >
          </a-space>
          <a-space v-else>
            <span
              style="color: #1890ff; cursor: pointer"
              @click="edit(text, index)"
              >编辑</span
            >
            <span style="color: #f00; cursor: pointer" @click="remove(index)"
              >删除</span
            >
          </a-space>
        </template>
      </a-table-column>
    </a-table>

    <a-button type="primary" style="margin-top: 12px" @click="add"
      >新增银行信息</a-button
    >
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      editingBank: {},
    };
  },

  methods: {
    add() {
      this.$emit("addBank");
    },
    remove(index) {
      this.$emit("removeBank", index);
    },
    edit(text, index) {
      this.editingBank = text;
      this.$emit("setBank", {
        index,
        element: {
          ...text,
          editable: true,
        },
      });
    },

    cancel(text, index) {
      this.editingBank = {};
      this.$emit("setBank", {
        index,
        element: {
          ...text,
          editable: undefined,
        },
      });
    },

    save(index) {
      this.$emit("setBank", {
        index,
        element: {
          ...this.editingBank,
          editable: undefined,
        },
      });
      this.editingBank = {};
    },
  },
};
</script>